
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import {useI18n} from 'vue-i18n';
import {defineComponent} from 'vue';

export default defineComponent(
    {
      components: {Header, Footer},
      props: {
        sentinalName: {
          type: String,
          required: true,
        },
      },
      name: 'ProductPage',
      setup() {
        const {t, locale} = useI18n();
        return {t, locale};
      },
      data() {
        return {
          s1_mastercard: false,
          s1_visa: false,
          s1_jcb: false,
          s2_firstchoice: false,
          s2_ktc: false,
          s2_krungsri: false,
          s3_dashboard: false,
          scrollPosition: 0,
          bottomScroll: false,
        };
      },
      methods: {
        s1AnimationToggle() {
          this.s1_mastercard = !this.s1_mastercard;
          this.s1_visa = !this.s1_visa;
          this.s1_jcb = !this.s1_jcb;
        },
        s2AnimationToggle() {
          this.s2_firstchoice = !this.s2_firstchoice;
          this.s2_ktc = !this.s2_ktc;
          this.s2_krungsri = !this.s2_krungsri;
        },
        updateScroll() {
          this.scrollPosition = window.scrollY;

          const s2 = this.$refs.s2 as any;
          if (s2 != null) {
            const result = s2.getBoundingClientRect().top - this.scrollPosition;
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              // mobile
              // section 1
              if (result < -560) {
                if (this.s1_mastercard) {
                  this.s1AnimationToggle();
                }
              } else {
                if (!this.s1_mastercard) {
                  this.s1AnimationToggle();
                }
              }

              // section 3
              if (result < -5170) {
                if (this.s3_dashboard) {
                  this.s3_dashboard = false;
                }
              }

              if (result < -4100 && result > -4780) {
                if (!this.s3_dashboard) {
                  this.s3_dashboard = true;
                }
              }

              if (result > -4780 && result < -4100) {
                if (!this.s3_dashboard) {
                  this.s3_dashboard = true;
                }
              }

              if (result > -4100) {
                if (this.s3_dashboard) {
                  this.s3_dashboard = false;
                }
              }
            } else {
              // desktop
              // section 2
              if (result < 400) {
                this.s2_firstchoice = true;
              }
              if (result < -150) {
                this.s2_ktc = true;
              }
              if (result < -350) {
                this.s2_krungsri = true;
              }

              // section 3
              if (result < -4100) {
                if (!this.s3_dashboard) {
                  this.s3_dashboard = true;
                }
              }

              if (result < -20) {
                this.s1AnimationToggle();
              } else {
                if (!this.s1_mastercard) {
                  this.s1AnimationToggle();
                }
              }

              if (result < -2400) {
                this.s2AnimationToggle();
              }

              if (result > 500) {
                if (this.s2_firstchoice) {
                  this.s2AnimationToggle();
                }
              }

              if (result > -3800) {
                if (this.s3_dashboard) {
                  this.s3_dashboard = !this.s3_dashboard;
                }
              }

              if (result < -6900) {
                this.s3_dashboard = !this.s3_dashboard;
              }
            }
          }
        },
      },
      mounted() {
        this.s1AnimationToggle();

        window.addEventListener('scroll', this.updateScroll);
      },
      destroy() {
        window.removeEventListener('scroll', this.updateScroll);
      },
    });
